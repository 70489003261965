<template>
    <div class="auth-section">
        <div class="image-section">
            <div class="image-content">
                <!--<img class="mx-auto img-fluid left-logo mt-5" width="230" height="65"
                    src="../../assets/images/logo.png"
                    alt="Logo">-->
                <h1 class="mt-3">Welcome to LongLeap.One</h1>
                <div class="mt-3">
                    <img class="img-fluid" src="../../assets/images/login.png" alt="">
                </div>
            </div>
        </div>

        <step-sign-up
            v-if="step === 0"
            :userData="userData"
            @user="getUser"
        />

        <step-verify
            v-if="step === 1"
            @goBack="firstScreen($event)"
            :user="user"
            @success="setPasscode"
        />

        <step-set-password
            v-if="step === 2"
            :passcode="passcode"
            :email="user.email"
        />
    </div>
</template>

<script>
import StepSignUp from "../../views/auth/SignUpStep/StepSignUp";
import StepVerify from "../../views/auth/SignUpStep/StepVerify";
import StepSetPassword from "../../views/auth/SignUpStep/StepSetPassword";

export default {
    name: "Signup",
    components: {StepSetPassword, StepVerify, StepSignUp},

    data() {
        return {
            step: 0,
            user: {},
            passcode: '',
            userData: null
        }
    },

    methods: {
      firstScreen(e) {
        this.userData = e.user
        this.step = 0;
      },
        getUser(user) {
            this.step = 1;
            this.user = user;
        },

        setPasscode(passcode) {
            this.step = 2;
            this.passcode = passcode;
        },
    },
};
</script>

<style>
.auth-section {
    display: flex;
    height: 100vh;
    justify-content: space-between;
    flex-wrap: wrap;
}

.image-section {
    width: 50%;
    background: #F3F3F3;
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
}

.image-section h1, .form-section h1 {
    font-weight: 700;
    margin-top: 30px;
}

.form-section {
    width: 50%;
    background: #FFFFFF;
    padding: 50px 20px;
}

.form-section img {
    display: none;
}

@media (max-width: 991px) {
    .image-section {
        display: none;
    }
    .form-section h1 {
        font-size: 30px;
        text-align: center;
    }
    .form-content img {
        width: 200px;
        margin-bottom: 30px;
    }

    .form-section {
        width: 100%;
    }

    .form-section img {
        text-align: center;
        display: block;
    }
}
@media (max-width: 767px) {
    .form-section h1 {
        font-size: 24px;
        text-align: center;
    }
    
    .form-section {
        padding: 30px 20px;
    }
    .form-content img {
    width: 150px;
    margin-bottom: 15px;
}
}
@media (max-width: 576px) {
    .pageTitle {
        font-size: 23px !important;
    }
}
</style>
