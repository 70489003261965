<template>
    <div class="form-section position-relative">
        <div id="timeline"
            class="position-absolute"
            style="width: 290px;top: 20px;">
            <el-steps :active="1" align-center
                finish-status="success">
                <el-step
                    title="Sign Up"></el-step>
                <el-step title="Verify"></el-step>
                <el-step
                    title="Set Password"></el-step>
            </el-steps>
        </div>
        <div class="form-content">
            <img class="mx-auto img mb-3"
                width="230"
                src="../../../assets/images/logo.png"
                alt="Logo">

            <h1 class="join_title mt-0">Join us
            </h1>
            <!--        <p>Just put your working email and a password <br>-->
            <!--            to get started.</p>-->

            <el-form ref="form" :model="formData"
                :rules="rules" class="mt-3"
                label-position="top">

                <el-form-item label="Email"
                    prop="email">
                    <el-input
                        v-model="formData.email"
                        type="email"
                        @keyup.enter.native="handleSubmit('form')"
                        placeholder="" />
                </el-form-item>

                <div class="row">
                    <div class="col-md-6">
                        <el-form-item
                            label="First Name"
                            prop="first_name">
                            <el-input
                                v-model="formData.first_name"
                                @keyup.enter.native="handleSubmit('form')"
                                placeholder="e.g. Sarah" />
                        </el-form-item>
                    </div>
                    <div class="col-md-6">
                        <el-form-item
                            label="Last Name"
                            prop="last_name">
                            <el-input
                                v-model="formData.last_name"
                                @keyup.enter.native="handleSubmit('form')"
                                placeholder="e.g. Brown" />
                        </el-form-item>
                    </div>
                </div>

                <el-form-item
                    :error="error.is_adult"
                    prop="is_adult">
                    <el-checkbox v-model="formData.is_adult">I am 18 years or older</el-checkbox>
                </el-form-item>

                <el-form-item :error="error.agree"
                    prop="agree">
                    <el-checkbox
                        v-model="formData.agree">I agree to the
                        <a href="https://longleap.one/wp-content/uploads/2023/01/LongLeap.One_PrivacyPolicy.pdf"
                            target="_blank" download>Privacy Policy</a> and
                        <a href="https://longleap.one/wp-content/uploads/2023/01/LongLeap.One_TermsConsiditions.pdf"
                            target="_blank" download> Terms of Use</a>
                    </el-checkbox>
                </el-form-item>

                <el-button class="mt-0"
                    style="width: 100%"
                    type="success"
                    :loading="loading"
                    @click="handleSubmit('form')">Sign Up for Free
                </el-button>

            </el-form>

            <div class="text-center mt-3">
                Do you already have an account?
                <router-link to="/signin">Sign In</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { Notification } from "element-ui";
import axios from "axios";

export default {
    name: "StepSignUp",
  props: {
      userData: {
        default: null
      }
  },
  mounted() {
      if (this.userData) {
        this.formData.email = this.userData.email
        this.formData.first_name = this.userData.first_name
        this.formData.last_name = this.userData.last_name
        this.formData.agree = true
        this.formData.is_adult = true
      }
  },
  data() {
        return {
            formData: {
                email: null,
                first_name: null,
                last_name: null,
                is_adult: false,
                agree: false,
                password: null,
                recaptcha: "",
            },

            rules: {
                email: [
                    { required: true, message: 'This field is required', trigger: 'blur' },
                    { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
                ],
                first_name: [
                    { required: true, message: 'This field is required', trigger: 'blur' },
                ],
                last_name: [
                    { required: true, message: 'This field is required', trigger: 'blur' },
                ],
                is_adult: [
                    { required: true, message: 'This field is required', trigger: 'blur' },
                ],
                agree: [
                    { required: true, message: 'This field is required', trigger: 'blur' },
                ],
            },

            error: {
                is_adult: '',
                agree: '',
            },

            loading: false,
        }
    },

    methods: {
        handleSubmit(formName) {
            this.error.is_adult = '';
            this.error.agree = '';
            if (!this.formData.is_adult) {
                this.error.is_adult = 'You must be at least 18 years old to use this service';
            }
            if (!this.formData.agree) {
                this.error.agree = 'You must agree to the terms and conditions';
            }
            if(!this.formData.is_adult || !this.formData.agree){
                return;
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.callToBackendRegistrationApi();
                } else {
                    return false;
                }
            });
        },

        callToBackendRegistrationApi() {
            this.loading = true;
            axios.post('/auth/signup', this.formData)
                .then((res) => {
                    Notification.success('A Passcode has been sent to your email');
                    this.$emit('user', res.data.user);
                    console.log(res.data.user);
                })
                .catch(error => {
                    if (error.response.status === 422) {
                        if (error.response.data.errors.email) {
                            Notification.warning(error.response.data.errors.email[0]);
                        }
                        this.error = error.response.data.errors;
                    } else {
                        Notification.error(error.response.data.message);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>

<style scoped>
@media (max-width: 576px) {
    .form-section img {
        display: block;
        text-align: left !important;
    }

    #timeline {
        display: block;
        width: 256px !important;
    }

    .join_title {
        font-size: 30px !important;
    }

}
</style>