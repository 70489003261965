<template>
    <div class="form-section">
        <div id="timeline"
            class="position-absolute"
            style="width: 290px;top: 20px;">
            <el-steps :active="2" align-center
                finish-status="success">
                <el-step
                    title="Sign Up"></el-step>
                <el-step title="Verify"></el-step>
                <el-step
                    title="Set Password"></el-step>
            </el-steps>
        </div>
        <div class="form-content text-center">
            <img class="mx-auto img-fluid"
                width="183" height="53"
                src="../../../assets/images/logo.png"
                alt="Logo">

            <h1 class="join_title mt-0">Verify
                your
                Account</h1>
            <p>Please enter the passcode sent on
                the
                registered email ({{ user.email }}).</p>

            <el-form ref="verifyForm" :model="formData"
                :rules="rules" class="mt-3"
                @submit.native.prevent="handleSubmit('verifyForm')"
                label-position="top">

                <el-form-item
                    label="Verification Code"
                    prop="verification_code">
                    <el-input
                        v-model="formData.verification_code"
                        placeholder="********" />
                </el-form-item>


                <el-button class="mt-0"
                    style="width: 100%"
                    type="success" :loading="loading"
                    @click.prevent="handleSubmit('verifyForm')">Submit
                </el-button>

            </el-form>

            <div class="text-center mt-3">
                <el-button
                    @click.prevent="resendPasscode"
                    type="text"
                    class="p-0 resend-btn">Resend
                    Passcode
                </el-button>
            </div>
          <div class="text-center mt-3">
            <el-button class="mt-0"
                       style="width: 100%"
                       type="success"
                       @click.prevent="back()">Back
            </el-button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { Notification } from "element-ui";

export default {
    name: "StepVerify",
    props: ['user'],
    data() {
        return {
            formData: {
                verification_code: null,
            },

            rules: {
                verification_code: [
                    { required: true, message: 'This field is required', trigger: 'blur' },
                    { min: 6, message: 'Verification code must be at least 6 characters', trigger: 'blur' }
                ]
            },

            loading: false,
        }
    },

    methods: {
      back() {
        this.$emit('goBack', {user: this.user, step: 0})
      },
        handleSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.callToVerificationApi();
                } else {
                    return false;
                }
            });
        },

        callToVerificationApi() {
            this.loading = true;
            axios.post('auth/validate-password', {
                email: this.user.email,
                password: this.formData.verification_code
            })
                .then(() => {
                    Notification.success("Passcode Verified!");
                    this.$emit('success', this.formData.verification_code);
                })
                .catch((error) => {
                    Notification.error(error.response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        resendPasscode() {
            axios.post('auth/resend-password', {
                email: this.user.email,
            })
                .then(() => {
                    Notification.success('Passcode has been sent to your email');
                })
                .catch((error) => {
                    Notification.error(error.response.data.message);
                })
        }
    }

}
</script>

<style scoped>
@media (max-width: 991px) {

    #timeline {
        display: block;
    }

}

@media (min-width: 351px) and (max-width: 576px) {
    .form-section img {
        display: block;
        text-align: left !important;
        margin: 0 0 !important;
        height: 35px !important;
    }

    #timeline {
        display: block;
        width: 200px !important;
        left: 40% !important;
    }

    .join_title {
        font-size: 23px !important;
        margin-top: 60px;
    }

}

@media (max-width: 350px) {

    #timeline {
        width: 200px !important;
        left: 50% !important;
        top: 8% !important;
        transform: translate(-50%, -50%) !important;
    }

    .form-section img {
        margin: 110px auto 30px !important;
        width: 170px !important;
        height: 55px !important;
    }

    .join_title {
        font-size: 23px !important;
        margin-top: 60px;
    }

}
</style>