<template>
    <div class="form-section">
        <div id="timeline" class="position-absolute"
             style="width: 290px;top: 20px;">
            <el-steps :active="3" align-center finish-status="success">
                <el-step title="Sign Up"></el-step>
                <el-step title="Verify"></el-step>
                <el-step title="Set Password"></el-step>
            </el-steps>
        </div>
        <div class="form-content">
            <img class="mx-auto img-fluid" width="183" height="53"
                src="../../../assets/images/logo.png"
                alt="Logo">

            <h1 class="join_title mt-0">Set Your Password</h1>
            <p>Please set your new password</p>

            <el-form
                ref="ruleForm"
                :model="formData"
                :rules="rules"
                class="mt-3 demo-ruleForm"
                label-position="top"
            >

                <el-form-item label="New Password" prop="password">
                    <el-input
                        v-model="formData.password"
                        @keyup.enter.native="handleSubmit('ruleForm')"
                        placeholder="********"
                        autocomplete="off"
                        :type="showPassword ? 'text' : 'password'"
                    >
                        <el-button slot="append" @click.prevent="showPassword = !showPassword">
                            <svg v-if="showPassword" style="width: 15px; height: 15px" fill="none" stroke="currentColor"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                            </svg>
                            <svg v-if="!showPassword" style="width: 15px; height: 15px" fill="none" stroke="currentColor"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                            </svg>
                        </el-button>
                    </el-input>
                </el-form-item>

                <el-form-item label="Confirm Password" prop="password_confirm">
                    <el-input
                        v-model="formData.password_confirm"
                        placeholder="********"
                        autocomplete="off"
                        @keyup.enter.native="handleSubmit('ruleForm')"
                        :type="showConfirmPassword ? 'text' : 'password'"
                    >
                        <el-button slot="append" @click.prevent="showConfirmPassword = !showConfirmPassword">
                            <svg v-if="showConfirmPassword" style="width: 15px; height: 15px" fill="none" stroke="currentColor"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                            </svg>
                            <svg v-if="!showConfirmPassword" style="width: 15px; height: 15px" fill="none" stroke="currentColor"
                                viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                    d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                            </svg>
                        </el-button>
                    </el-input>
                </el-form-item>

                <el-button
                    class="mt-3"
                    style="width: 100%" :loading="loading"
                    type="success"
                    @click.prevent="handleSubmit('ruleForm')">Submit
                </el-button>

            </el-form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {Notification} from "element-ui";

export default {
    name: "StepSetPassword",
    props: ["email", "passcode"],
    data() {
        var validatePass = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Please input the password'));
            } else {
                if (this.formData.password_confirm !== '') {
                    this.$refs.ruleForm.validateField('password_confirm');
                }
                callback();
            }
        };
        var validatePass2 = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('Please input the password again'));
            } else if (value !== this.formData.password) {
                callback(new Error('Two inputs don\'t match!'));
            } else {
                callback();
            }
        };
        return {
            formData: {
                password: '',
                password_confirm: '',
            },
            rules: {
                password: [
                    {
                      validator: validatePass,
                      trigger: 'blur'
                    },
                  // {
                  //   min: 8,
                  //   message: 'Password must be at least 8 characters',
                  //   trigger: 'blur'
                  // },
                  {
                    trigger: 'blur',
                    validator (rule, value, callback) {
                      if (/^(?=.*[a-zA-Z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^()])[A-Za-z\d@$!%*?&#^()]{8,}$/.test(value)) {
                        callback();
                      } else {
                        callback(new Error('Minimum 8 characters, One capital latter, Special charater, Alphanumeric'));
                      }
                    }
                  }
                ],
                password_confirm: [
                    {
                      validator: validatePass2,
                      trigger: 'blur'
                    },
                  {
                    min: 8,
                    message: 'Password must be at least 8 characters',
                    trigger: 'blur'
                  }

                ],

            },
            showPassword: false,
            showConfirmPassword: false,
            isSubmit: false,
            loading: false
        }
    },
    methods: {
        handleSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if(this.isSubmit == false){
                        this.passwordReset();
                    }
                } else {
                    return false;
                }
            });
        },

        passwordReset() {
            this.loading = true;
            this.isSubmit = true;
            axios.post('/auth/change-password', {
                password: this.formData.password,
                passcode: this.passcode,
                email: this.email,
            })
                .then(() => {
                    this.loading = false;
                    this.callToBackendLoginApi();
                })
                .catch(error => {
                    this.isSubmit = false;
                    Notification.error(error.response.data.message);
                })
        },

        callToBackendLoginApi() {
            this.$store.dispatch("auth/signin", {
                email: this.email,
                password: this.formData.password,
                passcode: this.passcode,
                remember_me: false
            })
                .then(() => {
                    this.$router.push({path: "/main-dashboard"});
                })
                .catch(() => {
                    this.isSubmit = false;
                    Notification.error('Something wents wrong! try again!');
                })
                .finally(() => {
                    this.isSubmit = false;
                });
        },
    }
}
</script>

<style scoped>

@media (max-width: 992px) {

    #timeline {
        display: block;
        right: 5%;
    }

}

@media (max-width: 576px) {
    .form-section img {
        display: block;
        text-align: left !important;
        margin: 0 0 !important;
        height: 35px !important;
    }

    #timeline {
        display: block;
        width: 200px !important;
        left: 40% !important;
    }

    .join_title {
        font-size: 25px !important;
        margin-top: 85px !important;
    }

}

@media (max-width: 350px) {

    #timeline {
        width: 200px !important;
        left: 50% !important;
        top: 8% !important;
        transform: translate(-50%, -50%) !important;
    }

    .form-section img {
        margin: 110px auto 30px !important;
        width: 170px !important;
        height: 55px !important;

    }
}

</style>